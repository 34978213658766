// src/pages/ForgotPassword.js
import React from 'react';
import { Container, Typography, TextField, Button, Grid, Paper } from '@mui/material';

const ForgotPassword = () => {
  const handleSubmit = (e) => {
    e.preventDefault();
    // Aquí se manejaría la lógica para enviar el correo de recuperación
    console.log("Recuperar contraseña...");
  };

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Recuperar Contraseña
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Correo Electrónico"
                name="email"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
              >
                Enviar enlace de recuperación
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ForgotPassword;
