import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Paper, Alert } from '@mui/material'; // Incluí el componente Alert para mostrar mensajes
import { Link, useNavigate } from 'react-router-dom'; // useNavigate para redirigir después de iniciar sesión

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Resetear los mensajes de estado
    setErrorMessage('');
    setSuccessMessage('');

    try {
      const response = await fetch('https://universidadvirtualdelperu.edu.pe/myapp/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password }) // Enviar username y password
      });

      const data = await response.json();
      console.log(data);
      
    if (response.ok) {
      if (data.confirmed) {  // Verifica si el correo ha sido confirmado
        setSuccessMessage(data.message);
        // Guarda el userId en localStorage (deberías recibir el userId del backend al iniciar sesión)
        localStorage.setItem('userId', data.userId); 
        localStorage.setItem('publicName', data.publicName);

        
        console.log('Redirigiendo a la página de cursos');
        setTimeout(() => {
          navigate('/courses'); // Redirige a la página de cursos
        }, 1500);
      } else {
        setErrorMessage("Por favor, confirma tu correo antes de iniciar sesión.");
      }
    } else if (response.status === 404) {
      setErrorMessage("Usuario no registrado");
    } else if (response.status === 401) {
      setErrorMessage("Contraseña incorrecta");
    } else {
      setErrorMessage(data.message);
    }
  } catch (error) {
    console.error('Error en la solicitud:', error);
    setErrorMessage('Error de conexión, intenta de nuevo.');
  }
};

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Iniciar Sesión
        </Typography>
        {errorMessage && (
          <Alert severity="error" style={{ marginBottom: '10px' }}>
            {errorMessage}
          </Alert>
        )}
        {successMessage && (
          <Alert severity="success" style={{ marginBottom: '10px' }}>
            {successMessage}
          </Alert>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Correo Electrónico"
                name="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Contraseña"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
                style={{ marginTop: '10px' }}
              >
                Iniciar Sesión
              </Button>
            </Grid>
          </Grid>
        </form>
        <Grid container justifyContent="flex-end">
          <Grid item>
            <Button color="secondary" component={Link} to="/forgot-password">
              ¿Olvidaste tu contraseña?
            </Button>
            <Button color="secondary" component={Link} to="/loginDocente">
              ingresar como Docente
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </Container>
  );
};

export default Login;
