import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Card, CardContent, Typography, CircularProgress,CardMedia } from '@mui/material';
import { styled } from '@mui/system';
import RecommendedCourses from '../components/RecommendedCourses';


const StyledCard = styled(Card)({
  margin: '16px',
  boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
  transition: '0.3s',
  display: 'flex', 
  width: '94%', // Configura el ancho del card
  height: '200px',
  '&:hover': {
    transform: 'scale(1.05)',
  },
});

const ImageContainer = styled(CardMedia)({
  width: '33%', // Ajusta el ancho de la imagen
  height: '100%',
});

const ContentContainer = styled(CardContent)({
  width: '60%', // Ajusta el ancho del contenido
});


const StudentCourses = () => {
  const [courses, setCourses] = useState([]);
  const [loading, setLoading] = useState(true); // Estado para carga
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('userId');
    if (!userId) {
      navigate('/login');
    } else {
      fetch(`https://universidadvirtualdelperu.edu.pe/myapp/user/${userId}/enrolled-courses`)
        .then(response => {
          if (!response.ok) {
            throw new Error('Error en la red al obtener cursos');
          }
          return response.json();
        })
        .then(data => {
          setCourses(data.enrolled_courses || []);
        })
        .catch(err => console.error("Error al obtener cursos:", err))
        .finally(() => setLoading(false)); // Detener carga al finalizar la solicitud
    }
  }, [navigate]);
  
  const handleCourseClick = (courseName) => {
    navigate(`/${courseName}`); // Redirige a la página del curso
  };

  return (
    <Grid container spacing={2}>
    <Grid item xs={12} md={8}>
      <h1>Tus Cursos</h1>
      {loading ? (
        <CircularProgress />
      ) : courses.length === 0 ? (
        <p>No estás matriculado en ningún curso.</p>
      ) : (
        <Grid container spacing={2}>
          {courses.map(course => (
            <Grid item xs={12} sm={6} key={course.id}>
              <StyledCard onClick={() => handleCourseClick(course.course_name)}>
                <ImageContainer
                  component="img"
                  image={course.image}
                  alt={course.course_name}
                />
                <ContentContainer>
                  <Typography variant="h5">{course.course_name}</Typography>
                  <Typography variant="body2" color="textSecondary">
                    {course.description}
                  </Typography>
                  <Typography variant="body2">Duración: {course.weeks} semanas</Typography>
                  <Typography variant="body2">Tipo: {course.course_type}</Typography>
                </ContentContainer>
              </StyledCard>
            </Grid>
          ))}
        </Grid>
      )}
    </Grid>
    <Grid item xs={12} md={4}>
      <RecommendedCourses /> {/* Muestra el componente de cursos recomendados en el lado derecho */}
    </Grid>
  </Grid>
  );
};

export default StudentCourses;

