// src/pages/Home.js
import React from 'react';
import { Container, Typography, Grid, Paper, Button, Box } from '@mui/material';
import { styled } from '@mui/system';
import Slider from 'react-slick';

import uvpImage from '../assets/uvp-image.jpg';


import { Link,useNavigate } from 'react-router-dom';

import { Carousel } from 'react-responsive-carousel';


// Estilos personalizados para las franjas de contenido
const Section = styled(Box)(({ theme }) => ({
  backgroundColor: '#332B22',
  color: '#fff',
  textAlign: 'center',
  padding: theme.spacing(10, 0),
}));

const WhiteSection = styled(Box)(({ theme }) => ({
  backgroundColor: '#fff',
  color: '#000',
  textAlign: 'center',
  padding: theme.spacing(10, 0),
}));



const Home = () => {
  const publicName = localStorage.getItem('publicName');
  


  return (
    <div>
     

       



      {/* Segunda franja: Qué es UVP? */}
      <WhiteSection>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <img 
                src={uvpImage} 
                alt="UVP" 
                style={{ 
                  width: '30%', 
                  borderRadius: '10px', 
                  boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)' 
                }} 
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                ¿Qué es UVP?
              </Typography>
              <Typography variant="body1" style={{ lineHeight: '1.7', marginTop: '20px' }}>
                La Universidad Virtual del Perú (UVP) es una institución dedicada a la enseñanza virtual, ofreciendo una amplia gama de programas y cursos para profesionales altamente capacitados en diversas áreas del conocimiento.
              </Typography>
              <Button variant="outlined" color="primary" size="large" style={{ marginTop: '20px' }}>
                Más Información
              </Button>
            </Grid>
          </Grid>
        </Container>
      </WhiteSection>

      

      {/* Tercera franja: Misión */}
      <Section style={{ background: '#1E1E2F' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ fontWeight: 'bold' }}>
            Misión
          </Typography>
          <Typography variant="body1" style={{ margin: '20px 0' }}>
          Nuestra misión es proporcionar una educación accesible, flexible y de alta calidad a estudiantes de todo el mundo, utilizando tecnología de vanguardia y un enfoque centrado en el aprendizaje.
          </Typography>
          <Button variant="contained" color="secondary" size="large">
            Conócenos
          </Button>
        </Container>
      </Section>


        {/* cuarta franja: Qué es UVP? */}
        <WhiteSection>
        <Container maxWidth="lg">
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              {/*imagen*/}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                ¿Visión?
              </Typography>
              <Typography variant="body1" style={{ lineHeight: '1.7', marginTop: '20px' }}>
              Ser la universidad virtual líder en América Latina, reconocida por la excelencia académica, la innovación en el uso de herramientas virtuales y el impacto positivo en la sociedad.
              </Typography>
              <Button variant="outlined" color="primary" size="large" style={{ marginTop: '20px' }}>
                Más Información
              </Button>
            </Grid>
          </Grid>
        </Container>
      </WhiteSection>






      {/* Sección de cursos por temas */}
      <Section style={{ background: '#1E1E2F' }}>
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '40px' }}>
            Cursos por Temas
          </Typography>
          <Grid container spacing={4} justifyContent="center">
            {['IA', 'Antenas', 'Biotecnología', 'Datos', 'Programación'].map((tema) => (
              <Grid item xs={6} sm={4} md={2} key={tema}>
                <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', borderRadius: '10px' }}>
                  <Typography variant="h6">{tema}</Typography>
                  {/* Aquí puedes añadir un icono para cada tema */}
                  <Button variant="outlined" color="secondary" style={{ marginTop: '10px' }}>Ver Cursos</Button>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Container>
      </Section>


      {/* Sección de cursos */}
      <WhiteSection>
        <Container maxWidth="lg">
          <Typography variant="h4" style={{ fontWeight: 'bold', marginBottom: '40px' }}>
            Nuestros Cursos
          </Typography>
          <Grid container spacing={4}>
            {/* Aquí puedes agregar más cursos */}
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', borderRadius: '10px' }}>
                <Typography variant="h6">Curso de IA</Typography>
                <Button variant="contained" color="primary">Ver Curso</Button>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', borderRadius: '10px' }}>
                <Typography variant="h6">Curso de Telemedicina</Typography>
                <Link to={`/course/Telemedicina`} style={{ textDecoration: 'none' }}>
                <Button variant="contained" color="primary" >Ver Curso</Button>
                </Link>
              </Paper>
            </Grid>
            <Grid item xs={12} sm={6} md={4}>
              <Paper elevation={3} style={{ padding: '20px', textAlign: 'center', borderRadius: '10px' }}>
               
                <Button variant="contained" color="primary">Ver Curso</Button>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </WhiteSection>


      {/* Sección final: Call to Action */}
      
      <WhiteSection>
        <Container maxWidth="lg" style={{ padding: '40px 0', textAlign: 'center' }}>
          <Paper elevation={3} style={{ padding: '20px', borderRadius: '10px',background: '#1E1E2F' }}>
            <Typography variant="h5" gutterBottom color="#fff">
              ¿Listo para comenzar tu carrera?
            </Typography>

            {publicName ? (
                  <>
                    <Link to="/search-courses" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary" size="large">
              Inscríbete Hoy
            </Button>
            </Link>
                    
                  </>
                ) : (
                  <>
                    <Link to="/register" style={{ textDecoration: 'none' }}>
                    <Button variant="contained" color="secondary" size="large">
              Inscríbete Hoy
            </Button>
            </Link>
                    
                  </>
                )}
           


          </Paper>
        </Container>
        </WhiteSection>
    </div>
  );
};

export default Home;
