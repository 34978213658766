// src/components/Layout.js
import React from 'react';
import Navbar from './Navbar';
import Footer from './Footer'; // Si ya creaste el componente Footer

const Layout = ({ children }) => {
  return (
    <div>
      {/* Barra de navegación */}
      <Navbar />

      {/* Contenido de la página */}
      <div>{children}</div>

      {/* Pie de página */}
      <Footer />
    </div>
  );
};

export default Layout;
