// Carousel.js
import React, { useState } from 'react';
import { Container, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import carousel1 from '../assets/Carsl1.jpg';
import carousel2 from '../assets/carousel2.jpg';
import carousel3 from '../assets/carousel3.jpg';

const images = [
  {
    src: carousel1, // Asegúrate de que las rutas sean correctas
    title: 'Bienvenido a la UVP',
    description: 'Educación virtual de calidad para el futuro de los profesionales',
    link: 'search-courses',
    style: {
        textAlign: 'left',
        color: 'black',
        fontSize: '2rem',
      },
    btn:'Explora Nuestor cursos'
  },
  {
    src: carousel2,
    title: 'Telemedicina',
    description: 'Innovación en la salud digital',
    link: 'Telemedicina',
    style: {
      textAlign: 'right',
      color: 'black',
      fontSize: '1.5rem',
    },
    btn:'Aprende más',
    
    descriptionC:'Curso introductorio a la telemedicina y su impacto en la salud moderna.',
    weeks:'4',
    course_type: 'Grabado IA',
    image:'/carousel2.jpg',
    course_Id:'1'
  },
  {
    src: carousel3,
    title: 'Ingeniería de Satélites',
    description: 'Proyecta tu carrera al espacio',
    link: 'ingenieria-satelites',
    style: {
      textAlign: 'center',
      color: 'black',
      fontSize: '1.5rem',
    },
    btn:'Inscribirme'
  }
];

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const navigate = useNavigate();

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleCourseClick = (courseId,description,weeks,course_type,course_im,course_Id) => {
    if (currentIndex>0){
    navigate(`/course/${images[currentIndex].link}`); // Navega a la página de descripción del curso
    localStorage.setItem('courseName', courseId);
    localStorage.setItem('courseDes', description);
    localStorage.setItem('courseWeek', weeks);
    localStorage.setItem('courseType', course_type);
    localStorage.setItem('imagen', course_im);
    localStorage.setItem('courseId', course_Id);}
    else{navigate(`/${images[currentIndex].link}`)}
  };

  return (
    <div className="carousel-container" style={{ position: 'relative', overflow: 'hidden' }}>
      <div className="carousel-slide" style={{
        backgroundImage: `url(${images[currentIndex].src})`,
        backgroundSize: 'cover',
        height: '630px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-start',
        color: 'black',
        position: 'relative',
        transition: 'background-image 0.5s ease-in-out'
      }}>
       <Container maxWidth="lg">
      <Typography
        variant="h1"
        style={{
          fontWeight: 'bold',
          marginBottom: '0x',
          textAlign: images[currentIndex].style.textAlign, // Aplicar alineación
          color: images[currentIndex].style.color, // Aplicar color
          fontSize: "4.5rem" // Aplicar tamaño de fuente
        }}
      >
        {images[currentIndex].title}
      </Typography>
      <Typography
        variant="h3"
        style={{
          marginBottom: '30px',
          textAlign: images[currentIndex].style.textAlign, // Aplicar alineación
          color: images[currentIndex].style.color, // Aplicar color
          fontSize: "1.3rem"// Aplicar tamaño de fuente
        }}
      >
        {images[currentIndex].description}
      </Typography>
      
      <Typography
        variant="h3"
        style={{
          marginBottom: '30px',
          textAlign: images[currentIndex].style.textAlign, // Aplicar alineación
          color: images[currentIndex].style.color, // Aplicar color
          fontSize: "1.3rem"// Aplicar tamaño de fuente
        }}

      ><Button variant='contained' color="secondary" onClick={() =>  handleCourseClick( images[currentIndex].title,images[currentIndex].descriptionC,images[currentIndex].weeks,images[currentIndex].course_type,images[currentIndex].image,images[currentIndex].id)}>
      {images[currentIndex].btn}
      </Button></Typography>
      
    </Container>
  </div>


      <button onClick={prevSlide} className="carousel-button left">
        ❮
      </button>
      <button onClick={nextSlide} className="carousel-button right">
        ❯
      </button>
      
      <div className="carousel-indicators">
        {images.map((_, index) => (
          <span
            key={index}
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
