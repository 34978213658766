// RecommendedCourses.js
import React, { useEffect, useState } from 'react';
import { Grid,Card, CardContent, Typography, CardMedia, CircularProgress } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)({
  marginBottom: '16px',
  display: 'flex',
  height: '120px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
 
});
const CoursesContainer = styled('div')({
    backgroundColor: '#f2f2f2', // Cambia el color a tu preferencia
    padding: '20px',
    borderRadius: '10px',
    marginTop: '100px'
  });

const ImageContainer = styled(CardMedia)({
  width: '35%',
});

const ContentContainer = styled(CardContent)({
  width: '65%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
});

const RecommendedCourses = () => {
  const [recommendedCourses, setRecommendedCourses] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('https://universidadvirtualdelperu.edu.pe/myapp/recommended-courses')
      .then(response => response.json())
      .then(data => {
        setRecommendedCourses(data.recommended_courses || []);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error al cargar cursos recomendados:', error);
        setLoading(false);
      });
  }, []);

  return (
    <Grid container >
    {/* Columna principal de cursos */}
    <Grid item xs={12} md={8}>

<CoursesContainer> 
      <h2>Cursos Recomendados</h2>
      {loading ? (
        <CircularProgress />
      ) : recommendedCourses.length === 0 ? (
        <p>No hay cursos recomendados en este momento.</p>
      ) : (
        recommendedCourses.map(course => (
          <StyledCard  key={course.id}>
            <ImageContainer
              component="img"
              image={course.image}
              alt={course.course_name}
            />
            <ContentContainer>
              <Typography variant="h6">{course.course_name}</Typography>
              <Typography variant="body2" color="textSecondary">
                {course.description}
              </Typography>
            </ContentContainer>
          </StyledCard>
        ))
      )}
    </CoursesContainer>
    </Grid>
    </Grid>
  );
};

export default RecommendedCourses;
