import React, { useEffect, useState } from 'react';
import { Box, Grid, TextField, Card, CardMedia, CardContent, Typography, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import axios from 'axios'; // Asegúrate de tener axios instalado

//import { Link } from 'react-router-dom';

const CourseSearch = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [coursesData, setCoursesData] = useState([]); // Estado para almacenar los cursos
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get('https://universidadvirtualdelperu.edu.pe/myapp/courses'); // Llama a la API para obtener los cursos
        setCoursesData(response.data.courses); // Actualiza el estado con los datos obtenidos
      } catch (error) {
        console.error('Error fetching courses:', error);
      }
    };

    fetchCourses();
  }, []); // Ejecuta el efecto al montar el componente

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredCourses = coursesData.filter((course) =>
    course.course_name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCourseClick = (courseId,description,weeks,course_type,course_im,course_Id) => {
    navigate(`/course/${courseId}`); // Navega a la página de descripción del curso
    localStorage.setItem('courseName', courseId);
    localStorage.setItem('courseDes', description);
    localStorage.setItem('courseWeek', weeks);
    localStorage.setItem('courseType', course_type);
    localStorage.setItem('imagen', course_im);
    localStorage.setItem('courseId', course_Id);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4" gutterBottom>
        Buscar Cursos
      </Typography>
      <TextField
        label="Buscar cursos"
        variant="outlined"
        fullWidth
        margin="normal"
        value={searchTerm}
        onChange={handleSearchChange}
      />
      <Grid container spacing={3} sx={{ marginTop: 2 }}>
        {filteredCourses.length > 0 ? (
          filteredCourses.map((course) => (
            <Grid item xs={12} sm={6} md={4} key={course.id}>
              <Card>
                <CardActionArea onClick={() => handleCourseClick(course.course_name,course.description,course.weeks,course.course_type,course.image,course.id)}>
                  <CardMedia
                    component="img"
                    height="200"
                    image={course.image} // Cambia esto para que use una imagen correspondiente al curso si la tienes
                    alt={course.course_name}
                  />
                  <CardContent>
                    <Typography variant="h6" component="div">
                      {course.course_name}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {course.description}
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          ))
        ) : (
          <Typography variant="body1" color="text.secondary">
            Cargando cursos...
          </Typography>
        )}
      </Grid>
    </Box>
  );
};
export default CourseSearch;
