import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography, Box } from '@mui/material';
import axios from 'axios'; 

const CourseDetail = () => {
  // Obtener información del localStorage
  const userId = localStorage.getItem('userId'); // Obtener el user_id del localStorage
  const courseId = localStorage.getItem('courseId'); // Obtener el course_id del localStorage
  const courseName = localStorage.getItem('courseName'); // Obtener el nombre del curso
  const description = localStorage.getItem('courseDes'); // Obtener la descripción del curso
  const weeks = localStorage.getItem('courseWeek'); // Obtener la duración en semanas
  const type = localStorage.getItem('courseType'); // Obtener el tipo de curso
  const imagen = localStorage.getItem('imagen'); // Obtener la imagen del curso (si es necesario)

  const navigate = useNavigate(); // Para redirigir después de la inscripción
  const [isEnrolled, setIsEnrolled] = useState(false); // Estado para verificar si está inscrito
 
  
  
  // Comprobar si el usuario ya está inscrito en el curso
  useEffect(() => {
   
    const checkEnrollment = async () => {
      try {
        const response = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/courses/${courseId}/check-enrollment`, {
          params: { user_id: userId }
        });

        if (response.data.isEnrolled) {
          setIsEnrolled(true); // Actualizar el estado si el usuario está inscrito
          
        }
        
        
        
        
      } catch (error) {
        console.log('Error al verificar la inscripción:', error);
      }
    };

    checkEnrollment();
  }, [courseId, userId]);



  const handleEnroll = async () => {
    try {
      const response = await axios.post(`https://universidadvirtualdelperu.edu.pe/myapp/courses/${courseId}/enroll`, {
        user_id: userId,
      });
      
      if (response.status === 200) {
        console.log('Inscripción exitosa');
        setIsEnrolled(true); // Cambiar el estado a inscrito
        navigate('/courses'); // Redirigir a la página de cursos del estudiante
      } else {
        console.log('Error al inscribirse en el curso:', response.data.message);
      }
    } catch (error) {
      console.log('Error al inscribirse en el curso:', error);
    }
  };
  const handleEnroll2 = async () => {
    navigate('/login')
  }
  return (
    <Box sx={{ padding: 2 }}>
      <Typography variant="h3">{courseName}</Typography>
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        <Typography variant="body1" sx={{ flexGrow: 1 }}>
          {description}
        </Typography>
        <img
          src={imagen} // Asegúrate de que esta ruta sea correcta
          alt={courseName}
          style={{ width: '300px', marginLeft: '20px' }}
        />
      </Box>
      <Box sx={{ marginTop: 2 }}>
        <Typography>Duración: {weeks} semanas</Typography>
        <Typography>Tipo: {type}</Typography>
      </Box>
      {!userId ? (<Button
        variant="contained"
        color= "secondary"
        sx={{ marginTop: 2 }}
        onClick={handleEnroll2}
        //disabled={activ} // Deshabilitar el botón si ya está inscrito
      >
        Inicia Sesión
      
      </Button>) :
      
      (<Button
        variant="contained"
        color={isEnrolled ? "primary" : "secondary"}
        sx={{ marginTop: 2 }}
        onClick={handleEnroll}
        disabled={isEnrolled } // Deshabilitar el botón si ya está inscrito
      >
       
        {isEnrolled ? "Ya estás inscrito" : "Inscríbete"}  
      
      </Button>)}
    </Box>
  );
};

export default CourseDetail;

