// src/pages/Register.js
import React, { useState } from 'react';
import { Container, Typography, TextField, Button, Grid, Paper, MenuItem, Select, InputLabel, FormControl } from '@mui/material';

const Register = () => {
  const [names, setNames] = useState('');
  const [lastName, setLastName] = useState('');
  const [publicName, setPublicName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [country, setCountry] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validar que las contraseñas coincidan
    if (password !== confirmPassword) {
      setMessage('Las contraseñas no coinciden');
      return;
    }

    // Crear un objeto con los datos del formulario
    const userData = {
      names,
      last_name: lastName,
      public_name: publicName,
      country,
      email,
      password,
      password2: confirmPassword,
    };

    try {
      const response = await fetch('https://universidadvirtualdelperu.edu.pe/myapp/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(userData),
      });

      const data = await response.json();

      if (response.ok) {
        setMessage("Registro exitoso. Confirmar email");
        console.log(data.message);
      } else if (response.status === 400) {
        setMessage(data.message);
      } else {
        setMessage('Error al registrarse. Intenta de nuevo.');
      }
    } catch (error) {
      console.error('Error de conexión:', error);
      setMessage('Error de conexión. Intenta de nuevo más tarde.');
    }
  };

  return (
    <Container component="main" maxWidth="xs" style={{ marginTop: '50px' }}>
      <Paper elevation={3} style={{ padding: '20px' }}>
        <Typography variant="h5" align="center" gutterBottom>
          Registro
        </Typography>
        {message && (
          <Typography variant="body2" color="error" align="center" gutterBottom>
            {message}
          </Typography>
        )}
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nombre(s)"
                name="names"
                value={names}
                onChange={(e) => setNames(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Apellidos"
                name="lastName"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Nombre Público"
                name="publicName"
                value={publicName}
                onChange={(e) => setPublicName(e.target.value)}
                required
                inputProps={{ maxLength: 15 }}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl variant="outlined" fullWidth required>
                <InputLabel>País</InputLabel>
                <Select
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  label="País"
                >
                  <MenuItem value="">
                    <em>Seleccione un país</em>
                  </MenuItem>
                  <MenuItem value="Perú">Perú</MenuItem>
                  <MenuItem value="Argentina">Argentina</MenuItem>
                  <MenuItem value="Chile">Chile</MenuItem>
                  <MenuItem value="Colombia">Colombia</MenuItem>
                  <MenuItem value="México">México</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Correo Electrónico"
                name="email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Contraseña"
                type="password"
                name="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Repetir Contraseña"
                type="password"
                name="confirmPassword"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                color="primary"
                style={{ marginTop: '10px' }}
              >
                Registrarse
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default Register;
