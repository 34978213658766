// src/components/Footer.js
import React from 'react';
import { Container, Grid, Typography } from '@mui/material';
import developerLogo from '../assets/logo-developer.png'; // Asegúrate de que la ruta sea correcta

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#1E1E2F', color: 'white', padding: '20px', marginTop: '40px' }}>
      <Container>
        <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
            <Typography variant="h6">Respaldo</Typography>
            <p>Esta plataforma cuenta con el respaldo de STP Ingeniería, empresa especializada en brindar soluciones para el sector de telecomunicaciones con más de 25 años de experiencia.</p>
            <img src={developerLogo} alt="Logo STP Ingeniería" style={{ height: '50px' }} />
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Síguenos</Typography>
            <p>
              <a href="https://facebook.com" style={{ color: 'white', textDecoration: 'none' }}>Facebook</a> |{' '}
              <a href="https://twitter.com" style={{ color: 'white', textDecoration: 'none' }}>Twitter</a> |{' '}
              <a href="https://linkedin.com" style={{ color: 'white', textDecoration: 'none' }}>LinkedIn</a>
            </p>
          </Grid>
          
          <Grid item xs={12} sm={4}>
            <Typography variant="h6">Contacto</Typography>
            <p>Dirección: Juvenal Denegri 241, Cercado de Lima 15034</p>
            <p>Teléfono: +(51) 996286161</p>
            <p>e-mail: plataforma@universidadvirtualdelperu.edu.pe</p>
          </Grid>
        </Grid>
      </Container>
    </footer>
  );
};

export default Footer;
