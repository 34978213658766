// src/components/CourseMenu.js
import React from 'react';
import { Box, Typography, Tabs, Tab } from '@mui/material';

const CourseMenu = ({ selectedTab, handleTabChange }) => {
  return (
    <Box sx={{ width: 240, padding: 2 }}>
      <Typography variant="h6">Opciones</Typography>
      <Tabs
        orientation="vertical"
        value={selectedTab}
        onChange={handleTabChange}
        sx={{ borderRight: 1, borderColor: 'divider' }}
      >
        <Tab label="Semanas" />
        
        <Tab label="Wiki" />
        <Tab label="Foro" />
        <Tab label="Tareas" />
        <Tab label="Exámenes" />
      </Tabs>
    </Box>
  );
};

export default CourseMenu;
