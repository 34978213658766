// src/pages/Telemedicina.js
import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, TextField, List, ListItem, ListItemText} from '@mui/material';
import CourseMenu from '../components/CourseMenu'; // Importa el menú
import axios from 'axios';
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Snackbar, Alert } from '@mui/material';

const classLinks = {
  1: 'https://view.genially.com/5dd419618ae3380fc52a9a81',
  2: 'https://view.genially.com/5dd563b24b58d50fd176f7d9',
  3: 'https://view.genially.com/5deec5a3ceada80fbaabf3e4',
  4: 'https://view.genially.com/5e4abbe45f078f3328c5a974',
  5: 'https://view.genially.com/5e67f356a135f67114e5b249',
  6: 'https://view.genially.com/5e4ecfbb2e0566316ffb0bb1',
};

const Telemedicina = () => {
  const [selectedTab, setSelectedTab] = useState(0); // Controla las pestañas
  const [selectedClass, setSelectedClass] = useState(1); // Clase seleccionada por defecto
  const [docLinks, setDocLinks] = useState([]); // Almacena los enlaces de Google Docs
  const [newDocLink, setNewDocLink] = useState(''); // Nuevo enlace a agregar
  const [selectedDoc, setSelectedDoc] = useState(null); // Documento seleccionado para ver
  
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [selectedLink, setSelectedLink] = useState('');
  const [publicName, setPublicName] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  

  const userId =  localStorage.getItem('userId');
  useEffect(() => {
    // Cargar enlaces de documentos del usuario al montar el componente
    const fetchDocumentLinks = async () => {
      try {
        const response = await axios.get(`https://universidadvirtualdelperu.edu.pe/myapp/documents/${userId}/links`);
        setDocLinks(response.data.links);
      } catch (error) {
        console.error('Error al cargar los enlaces de documentos:', error);
      }
    };

    fetchDocumentLinks();
  }, [userId]);


  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setSelectedClass(1); // Reinicia a la primera clase cuando cambia la pestaña
  };

  const handleNextClass = () => {
    if (selectedClass < Object.keys(classLinks).length) {
      setSelectedClass(selectedClass + 1);
    }
  };

  const handlePreviousClass = () => {
    if (selectedClass > 1) {
      setSelectedClass(selectedClass - 1);
    }
  };

  ////////// NUEVO: Wiki colaborativo ////////////
  const handleAddDocument = async () => {
    if (newDocLink.trim()) {
      try {
        // Guardar el enlace en la base de datos
        await axios.post('https://universidadvirtualdelperu.edu.pe/myapp/documents/save-link', {
          user_id: userId,
          link: newDocLink,
        });

        // Actualizar el estado local
        setDocLinks([...docLinks, { link: newDocLink }]);
        setNewDocLink('');
      } catch (error) {
        console.error('Error al guardar el enlace:', error);
      }
    }
  };

  const handleDeleteDocument = async (link) => {
    try {
      // Borrar el enlace de la base de datos
      const response = await axios.delete(`https://universidadvirtualdelperu.edu.pe/myapp/documents/delete-link`, { data: { link } });
      console.log(response.data.message);  // Verificar mensaje de éxito

      // Actualizar el estado local
      setDocLinks(docLinks.filter(doc => doc.link !== link));
    } catch (error) {
      console.error('Error al borrar el enlace:', error);
      if (error.response) {
          console.error('Error response data:', error.response.data);
    }
  }
  };


  const handleSelectDocument = (link) => {
    setSelectedDoc(link);
  };

//////////////////////////////////

  const handleShareLink = async () => {
    try {
      const response = await axios.post('https://universidadvirtualdelperu.edu.pe/myapp/documents/share-link', {
        public_name: publicName,
        link: selectedLink,
      });
      setSnackbarMessage(response.data.message);
      setSnackbarOpen(true);
      setShareDialogOpen(false);  // Cerrar el diálogo
    } catch (error) {
      if (error.response) {
        setSnackbarMessage(error.response.data.message);
      } else {
        setSnackbarMessage('Error al compartir el enlace.');
      }
      setSnackbarOpen(true);
    }
  };

  const handleOpenShareDialog = (link) => {
    setSelectedLink(link);
    setShareDialogOpen(true);
  };

    // Función para cerrar el cuadro de diálogo
    const handleCloseShareDialog = () => {
      setShareDialogOpen(false);
      setPublicName('');
    };
  

  /////////////////////////////////////////////

  return (
    <Box>
      {/* Componente de Título */}
      <Box sx={{ padding: 2, textAlign: 'center', backgroundColor: '#f5f5f5' }}>
        <Typography variant="h3">Telemedicina</Typography>
      </Box>

      {/* Contenido con menú lateral y contenido principal */}
      <Box sx={{ display: 'flex', marginTop: 2 }}>
        {/* Menú lateral */}
        <CourseMenu selectedTab={selectedTab} handleTabChange={handleTabChange} />

        {/* Contenido principal */}
        <Box sx={{ flexGrow: 1, padding: 3 }}>
          {/* Pestaña de "Semanas" */}
          {selectedTab === 0 && (
            <Box>
              <Typography variant="h5" gutterBottom>
                Semana {Math.ceil(selectedClass / 2)} {/* Asumimos 2 clases por semana */}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Button onClick={handlePreviousClass} disabled={selectedClass === 1}>
                  Clase Anterior
                </Button>
                <Box sx={{ marginX: 2 }}>
                  <Typography variant="h6">Clase {selectedClass}</Typography>
                </Box>
                <Button
                  onClick={handleNextClass}
                  disabled={selectedClass === Object.keys(classLinks).length}
                >
                  Siguiente Clase
                </Button>
              </Box>

              {/* Mostrar el documento en un iframe */}
              <Box sx={{ marginTop: 3, border: '1px solid #ccc', borderRadius: 2 }}>
                <iframe
                  title={`Clase ${selectedClass}`}
                  src={classLinks[selectedClass]}
                  width="100%"
                  height="500px"
                  frameBorder="0"
                  allowFullScreen
                ></iframe>
              </Box>
            </Box>
          )}

          

          {/* Pestaña de "Wiki" */}
          {selectedTab === 1 && (
            <Box>
              
              <Typography variant="h5" gutterBottom>Wiki colaborativo</Typography>
                            {/* Botón para abrir Google Docs */}
                            <Box sx={{ marginBottom: 2 }}>
                <Button 
                  variant="contained" 
                  onClick={() => window.open('https://docs.google.com/document/u/0/?hl=es&pli=1', '_blank')}>
                  Abrir Google Docs
                </Button>
              </Box>

           
               
                        



              {/* Formulario para agregar documentos */}
              <Box sx={{ marginBottom: 2, display: 'flex', alignItems: 'center' }}>
                <TextField
                  label="Enlace de Google Docs"
                  variant="outlined"
                  value={newDocLink}
                  onChange={(e) => setNewDocLink(e.target.value)}
                  sx={{ marginRight: 2 }}
                />
                <Button variant="contained" onClick={handleAddDocument}>
                  Agregar Documento
                </Button>
              </Box>

              {/* Lista de documentos agregados */}
             <List>
                {docLinks.map((doc, index) => (
                  <ListItem key={index} button onClick={() => handleSelectDocument(doc.link)}>
                    <ListItemText primary={`Documento ${index + 1}`} />
                    <Button onClick={() => handleOpenShareDialog(doc.link)}>Compartir</Button>
                    <Button onClick={() => handleDeleteDocument(doc.link)}>Borrar</Button>
                  </ListItem>
                ))}
              </List>


               {/* Dialog para compartir el enlace */}
      <Dialog open={shareDialogOpen} onClose={handleCloseShareDialog}>
        <DialogTitle>Compartir Documento</DialogTitle>
        <DialogContent>
          <TextField
            label="Nombre público del usuario"
            value={publicName}
            onChange={(e) => setPublicName(e.target.value)}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseShareDialog}>Cancelar</Button>
          <Button onClick={handleShareLink} variant="contained" color="primary">Compartir</Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar para mensajes de éxito o error */}
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        autoHideDuration={4000}
      />


              {/* Mostrar el documento seleccionado en un iframe */}
              {selectedDoc && (
                <Box sx={{ marginTop: 3, border: '1px solid #ccc', borderRadius: 2 }}>
                  <iframe
                    title="Wiki Google Docs"
                    src={`${selectedDoc}?embedded=true`} // Mostrar el documento embebido
                    width="100%"
                    height="600px"
                    frameBorder="0"
                    allow="fullscreen"
                  ></iframe>
                </Box>
              )}
            </Box>
          )}

          {selectedTab === 3 && <Typography variant="h5">Foro</Typography>}
          {selectedTab === 4 && <Typography variant="h5">Tareas</Typography>}
          {selectedTab === 5 && <Typography variant="h5">Exámenes</Typography>}
        </Box>
      </Box>
    </Box>
  );
};

export default Telemedicina;

