// src/components/Navbar.js
import React, { useState } from 'react';
import { AppBar, Toolbar, Button, Typography, Menu, MenuItem, IconButton, Drawer, List, ListItem} from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { useMediaQuery } from '@mui/material'; // Para detectar pantallas pequeñas
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import logo from '../assets/logo uvp largo 2.png';

const Navbar = () => {
  const publicName = localStorage.getItem('publicName');
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);
  const [drawerOpen, setDrawerOpen] = useState(false); // Estado para el Drawer en móviles
  const open = Boolean(anchorEl);

  const isMobile = useMediaQuery('(max-width:600px)'); // Detectar si la pantalla es pequeña

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen); // Abrir o cerrar el Drawer
  };

  const handleLogout = () => {
    localStorage.removeItem('userId');
    localStorage.removeItem('publicName');
    handleMenuClose();
    navigate('/');
  };

  // Menú para usuarios autenticados
  const authenticatedMenu = (
    <>
      <IconButton 
        color="inherit" 
        onClick={handleMenuClick} 
        sx={{ textTransform: 'none' }}
      >
        <AccountCircleIcon />
        <Typography variant="subtitle1" sx={{ marginLeft: 1 }}>
          {publicName}
        </Typography>
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          elevation: 3,
          sx: { minWidth: 150 }
        }}
      >
        <MenuItem onClick={() => { handleMenuClose(); navigate('/courses'); }}>
          Mis cursos
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); navigate('/search-courses'); }}>
          Buscar cursos
        </MenuItem>
        <MenuItem onClick={() => { handleMenuClose(); navigate('/account'); }}>
          Cuenta
        </MenuItem>
        <MenuItem onClick={handleLogout}>
          Cerrar sesión
        </MenuItem>
      </Menu>
    </>
  );

  // Menú para usuarios no autenticados
  const guestMenu = (
    <>
      <Link to="/login" style={{ textDecoration: 'none' }}>
        <Button color="secondary">Iniciar Sesión</Button>
      </Link>
      <Link to="/register" style={{ textDecoration: 'none' }}>
        <Button color="secondary">Registrarse</Button>
      </Link>
    </>
  );

  return (
    <AppBar position="static" sx={{ backgroundColor: 'principal' }}>
      <Toolbar>
        {/* Menú de hamburguesa para pantallas móviles */}
        {isMobile ? (
          <>
            <IconButton
              color="inherit"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
            <Drawer
              anchor="left"
              open={drawerOpen}
              onClose={handleDrawerToggle}
            >
              <List>

                <ListItem button onClick={() => { handleDrawerToggle(); navigate('/'); }}>
                <Button color="inherit">{publicName}</Button>
                  
                  
                </ListItem>
                
                
                {publicName ? (
                  <>
                    <ListItem button onClick={() => { handleDrawerToggle(); navigate('/courses'); }}>
                    <Button color="inherit">Mis cursos</Button>
                    </ListItem>
                    <ListItem button onClick={handleLogout}>
                    <Button color="inherit">Cerrar sesión</Button>
                    </ListItem>
                  </>
                ) : (
                  <>
                    <ListItem button onClick={() => { handleDrawerToggle(); navigate('/login'); }}>
                    <Button color="inherit">Iniciar sesión</Button>
                    </ListItem>
                    <ListItem button onClick={() => { handleDrawerToggle(); navigate('/register'); }}>
                    <Button color="inherit">Registrarse</Button>
                    </ListItem>
                  </>
                )}
              </List>
            </Drawer>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            </Link>
          </>
        ) : (
          <>
            <Link to="/" style={{ textDecoration: 'none', color: 'inherit' }}>
              <img src={logo} alt="Logo" style={{ height: '40px', marginRight: '10px' }} />
            </Link>
            <Typography variant="h6" sx={{ flexGrow: 1 }}></Typography>
            {publicName ? authenticatedMenu : guestMenu}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;

